import { useState, useEffect, useCallback } from "react";
import "fp-web-uploads";
import "./App.css";
import InputText from "./InputText";

export default function App() {
  const [signedUrls, setSignedUrls] = useState(Array(10).fill(""));
  const [inputPullVideo, setInputPullvideo] = useState("");
  const [inputVideoRes, setInputVideoRes] = useState(false);
  const [isPaused, setIsPaused] = useState(Array(10).fill(false));
  const [uploadStarted, setUploadStarted] = useState(Array(10).fill(false));
  const [chunkUploading, setChunkUploading] = useState(Array(10).fill({ totalChunks: 0, currentChunk: 0 }));

  const getSignedUrl = useCallback(async (index, uploadId) => {
    const url = 'https://v1.fastpix.io/on-demand/uploads';
    const accessTokenId = '3284e8d3-e195-4712-a482-341e69ff2b43';
    const secretKey = '9d1ae7ad-0652-40c1-947a-9396a5f72001';
    const requestData = {
      corsOrigin: "*",
      time: "86400",
      pushMediaSettings: {
        accessPolicy: "public",
        maxResolution: "1080p"
      }
    };

    const encodedCredentials = btoa(`${accessTokenId}:${secretKey}`);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (data?.success) {
        setSignedUrls((prevUrls) => {
          const updatedUrls = [...prevUrls];
          updatedUrls[index] = data.data.url;
          return updatedUrls;
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (uploadId) {
      const uploadElement = document.getElementById(uploadId);

      if (uploadElement) {
    uploadElement.addEventListener("uploadStart", () => {
      toggleUploadStart(index);
    });

    uploadElement.addEventListener("success", () => {
      toggleUploadEnd(index);
    });

    uploadElement.addEventListener("chunkAttempt", (event) => {
      toggleChunkUploading(index, event);
    });
      }
    }
  }, []);

  const handlePauseUpload = (uploadId, index) => {
    if (uploadId) {
      const pauseId = document.getElementById(uploadId);

      if (pauseId) {
        pauseId.pause();
        togglePauseState(index);
      }
    }
  };

  const handleResumeUpload = (uploadId, index) => {
    if (uploadId) {
      const resumeId = document.getElementById(uploadId);

      if (resumeId) {
        resumeId.resume();
        togglePauseState(index);
      }
    }
  };

  const toggleUploadStart = (index) => {
    setUploadStarted((prevPaused) => {
      const updatedPaused = [...prevPaused];
      updatedPaused[index] = !updatedPaused[index];
      return updatedPaused;
    });
  };

  const toggleChunkUploading = (index, event) => {
    setChunkUploading((prevPaused) => {
      const updatedPaused = [...prevPaused];
      updatedPaused[index] = { currentChunk: event?.detail?.chunkNumber, totalChunks: event?.detail?.totalChunks };
      return updatedPaused;
    });
  };

  const toggleUploadEnd = (index) => {
    setUploadStarted((prevPaused) => {
      const updatedPaused = [...prevPaused];
      updatedPaused[index] = false;
      return updatedPaused;
    });
  };

  const togglePauseState = (index) => {
    setIsPaused((prevPaused) => {
      const updatedPaused = [...prevPaused];
      updatedPaused[index] = !updatedPaused[index];
      return updatedPaused;
    });
  };

  const renderPushVideoComponent = () => {

    return Array.from({ length: 10 }).map((_, i) => (
      signedUrls[i] &&
      <div key={i} className="container">
        <h1>Upload {i + 1}</h1>
        <fp-upload class="fpUpload" retry-chunk-attempt="20" id={`fpWebUploads${i + 1}`} endpoint={signedUrls[i]}>
        </fp-upload>
        <fp-upload-drop class="fpUpload1" fp-upload={`fpWebUploads${i + 1}`}>
          <fp-upload-status fp-upload={`fpWebUploads${i + 1}`}></fp-upload-status>
          <fp-upload-monitor progress-type="progress-status" fp-upload={`fpWebUploads${i + 1}`}></fp-upload-monitor>
          <fp-upload-monitor fp-upload={`fpWebUploads${i + 1}`}></fp-upload-monitor>
          <fp-upload-file-select fp-upload={`fpWebUploads${i + 1}`}>
            <button
              className="uploadButton"
              slot="upload-file-button"
              onClick={() => getSignedUrl(i, `fpWebUploads${i + 1}`)}
            >
              Select your file for Upload {i + 1}
            </button>
          </fp-upload-file-select>
          <fp-upload-retry fp-upload={`fpWebUploads${i + 1}`}></fp-upload-retry>

          {uploadStarted[i] &&
            <div style={{ marginTop: "20px" }}>
              <span>Chunk Uploading - <span style={{ color: "#0f0f0f" }}>{`${chunkUploading[i].currentChunk ? chunkUploading[i].currentChunk : 0}/${chunkUploading[i].totalChunks ? chunkUploading[i].totalChunks : 0}`}</span></span>
            </div>
          }
        </fp-upload-drop>
        {uploadStarted[i] &&
          <div className="pauseResume">
            <div>
              {isPaused[i] ? (
                <button className="resumeBtn" onClick={() => handleResumeUpload(`fpWebUploads${i + 1}`, i)}>Resume</button>
              ) : (
                <button className="pauseBtn" onClick={() => handlePauseUpload(`fpWebUploads${i + 1}`, i)}>Pause</button>
              )}
            </div>
          </div>
        }
      </div>
    ));
  };

  const handlePullVideoInputChange = (event) => {
    setInputPullvideo(event.target.value);
  };

  const uploadPullVideo = async () => {
    if (inputPullVideo) {
      const defaultPullRes = {
        "inputs": [
          {
            "type": "video",
            "url": inputPullVideo
          }
        ],
        "metadata": {
          "key1": "value1"
        },
        "accessPolicy": "public",
        "maxResolution": "1080p"
      };

      const pullUrl = "https://v1.fastpix.io/on-demand";
      const accessTokenId = '3284e8d3-e195-4712-a482-341e69ff2b43';
      const secretKey = '9d1ae7ad-0652-40c1-947a-9396a5f72001';
      const encodedCredentials = btoa(`${accessTokenId}:${secretKey}`);

      try {
        const response = await fetch(pullUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(defaultPullRes),
        });

        const data = await response.json();

        if (data?.success) {
          setInputVideoRes(true);
          setInputPullvideo("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setInputPullvideo("");
      setInputVideoRes(false);
    }
  };

  const renderPullVideoComponent = () => {

    return (
      <div className="pullVideoContainer">
        <h1>Upload By Providing URL in Input Field</h1>
        <div className="inputUpload">
          {inputVideoRes ? (
            <h2>Video Uploaded successfully</h2>
          ) : (
            <InputText
              value={inputPullVideo}
              onChange={(e) => handlePullVideoInputChange(e)}
            />
          )}
          <button
            className="uploadButton"
            style={{ marginLeft: "20px" }}
            onClick={() => uploadPullVideo()}
          >
            {inputVideoRes ? "Upload Again" : "Upload Video"}
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      if (!signedUrls[i]) {
        getSignedUrl(i);
      }
    }
  }, [signedUrls, getSignedUrl]);

  return (

    <div className="mainContainer">
      <div className="pullVideo">Pull Video</div>
      {renderPullVideoComponent()}
      <div className="pushVideo">Push Parallel Videos</div>
      {renderPushVideoComponent()}
    </div>
  );
}
