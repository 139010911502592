const InputText = (props) => {

    const handleInputChange = (event) => {
        let newValue = event.target.value;
        const MainName = event.target.name;

        if (newValue.startsWith(" ")) {
            newValue = newValue.trimStart();
        }

        if (props.onChange) {
            props.onChange({ ...event, target: { ...event.target, value: newValue, name: MainName } });
        }
    }

    return (
        <label className={`w-[${props.width || "100%"}] inputBlock`}>
            <input
                type="text"
                value={props?.value}
                onChange={(e) => handleInputChange(e)}
                spellCheck={false}
                className="custom-input"
            />
        </label>
    )
}

export default InputText
